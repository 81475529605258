import React, {useEffect, useRef, useState} from 'react'
import { graphql } from "gatsby"
import Layout from "@components/layout"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import queryString from 'query-string';
import getStripe from "../util/stripejs"

const Subscription = ({ location, data }) => {
  const {token} = queryString.parse(location.search);

  const donateBgImg = getImage(data.donateBg)

  const emailRef = useRef()

  const [isSend, setIsSend] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [msg, setMsg] = useState({success: false, message: ''})

  useEffect(() => {
    if(token){
      manageAccount(token)
      .then(data => {
        if(data.success){
          if(data?.redirectToCheckout){
            redirectToCheckout(data)
          }else{
            if(data?.session?.url) window.location.href = data?.session?.url
          }
        }else{
          if(data?.error?.name === "TokenExpiredError"){
            setMsg({success: data.success, message: "The token is expired! Click generate button to generate new token."})
          }else if(data?.error?.name === "JsonWebTokenError"){
            setMsg({success: data.success, message: "Invalid token! Click generate button to generate new token."})
          }else{
            setMsg({success: data.success, message: data.message})
          }

          setIsLoading(false)
        }
      })
    }else{
      setIsLoading(false)
    }
  }, [token])

  async function manageAccount(token) {
    const response = await fetch('/.netlify/functions/manage-account', {
      method: 'POST',
      body: JSON.stringify({token})
    });

    return await response.json();
  }

  async function redirectToCheckout(data) {
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      sessionId: data.session.id
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    if(emailRef.current.value){
      try {
        setMsg({success: false, message: ''})
        setIsSend(true)

        const response = await fetch('/.netlify/functions/get-subscription', {
          method: 'POST',
          body: JSON.stringify({email: emailRef.current.value})
        });
        const data = await response.json();
        setMsg({success: data.success, message: data.message})
      } catch (error) {
        console.log(error)
        setMsg({success: false, message: "Encounter an error."})
      } finally {
        setIsSend(false)
      }
    }
  }

  return (
      <Layout location={location}>
          <BgImage image={donateBgImg} className="w-100 h-100">
              <div id="donate">
                  <div className="container d-flex justify-content-center">
                      <div className="text-white w-100" style={{maxWidth: 600}}>
                        {isLoading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {msg.message && (
                              <div className={`alert ${msg.success ? 'alert-success' : 'alert-danger'}`} role="alert">{msg.message}</div>
                            )}

                            {!token ? (
                              <>
                                <h4 className="mb-4 text-center">Manage Subscription</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email Address</label>
                                        <input ref={emailRef} type="email" className="form-control" id="email" aria-describedby="emailHelp" />
                                        {/* <small id="emailHelp" className="form-text text-white">We'll never share your email with anyone else.</small> */}
                                    </div>
                                    <button type="submit" className="btn w-100 btn-primary mb-2">
                                      {isSend ? "Sending..." : "Send"}
                                    </button>
                                </form>
                              </>
                            ) : (
                              <a href="/subscription" className="btn btn-primary">Generate</a>
                            )}
                          </>
                        )}
                      </div>
                  </div>
              </div>
          </BgImage>
      </Layout>
  )
}

export default Subscription

export const accountPageQuery = graphql`
  query accountPageQuery {
    donateBg: file(relativePath: { eq: "donate-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`